



<template>

    <Section :options="{section:true, container:false}" class="pt-5" id="team-alt" >

         <div class="heading-block center">
            <h2>Les avocats</h2>
        </div>

        <div class="row align-items-stretch">

            <div class="col-lg-4 p-0 team-image">
                <div>
                    <img src="@public/img/bensadevot/Louis Bensa - 02.jpg" alt="Louis Bensa">
                    <img src="@public/img/bensadevot/Louis Bensa.jpg" alt="Louis Bensa">
                </div>
            </div>

            <div class="col-lg-8 p-4 ps-5 pe-5 team-description">
                <div class="vertical-middle">
                    <div class="heading-block mb-4">
                        <span class="before-heading color">Avocat</span>
                        <h3>Louis Bensa</h3>
                    </div>

                    <div class="row col-mb-50">

                        <div class="col-lg-12">
                            <p>
                                Louis Bensa est le fils de deux avocats <br/>

                                Par son père le bâtonnier Jean Claude Bensa il est la cinquième génération à exercer la profession d’avocat au barreau de Nice <br/>

                                Son arrière grand oncle Louis Bensa était bâtonnier dans les années 1905-1907<br/>

                                Son arrière grand père Denis Ciaudo était bâtonnier en 1948<br/>

                                Sa mère Dominique Bonnemaison Bensa a exercé dans un cabinet spécialisé en droit de la construction <br/>

                                L’avocature est une tradition familiale pour Louis Bensa.<br/>

                                Avant de s’installer à Nice, Louis Bensa a travaillé à Londres, en qualité de juriste, puis à Paris en qualité d’Avocat dans des cabinets exerçant le droit des affaires.
                            </p>
                            <h4>Domaines d’activités dominantes :</h4>
                            <ul class="ps-4">
                                <li>Droit du travail</li>
                                <li>Droit des affaires</li>
                                <li>Droit pénal</li>
                            </ul>
                            <p>Date de prestation de serment: 6 janvier 2010</p>
                            <p>Diplôme de Juriste Conseil d’Entreprise</p>
                            <p>Français et Anglais</p>
                            <a href="https://www.linkedin.com/in/louis-bensa-06848418/" target="_blank" class="social-icon inline-block si-small si-light si-rounded si-linkedin">
                                <i class="icon-linkedin"></i>
                                <i class="icon-linkedin"></i>
                            </a>
                            <!--
                            <a href="#" class="social-icon inline-block si-small si-light si-rounded si-twitter">
                                <i class="icon-twitter"></i>
                                <i class="icon-twitter"></i>
                            </a>
                            <a href="#" class="social-icon inline-block si-small si-light si-rounded si-gplus">
                                <i class="icon-gplus"></i>
                                <i class="icon-gplus"></i>
                            </a>
                            -->
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="row align-items-stretch">

            <div class="col-lg-4 p-0 order-md-last team-image">
                <div>
                    <img src="@public/img/bensadevot/Frederic Devot.jpg" alt="Frédéric Devot">
                    <!-- <img src="@public/img/bensadevot/profile-01.jpg" alt="Frédéric Devot"> -->
                </div>
            </div>

            <div class="col-lg-8 p-4 ps-5 pe-5 team-description">
                <div class="vertical-middle">
                    <div class="heading-block mb-4">
                        <span class="before-heading color">Avocat</span>
                        <h3>Frédéric Devot</h3>
                    </div>

                    <div class="row col-mb-50">

                        <div class="col-lg-12">
                            <p>
                                Issu d’une famille de juristes niçois, Frédéric DEVOT a exercé pendant de nombreuses années aux côtés du Bâtonnier Jean-Claude Bensa qui lui a transmis ses valeurs de dignité, de conscience, d’indépendance, de probité et d’humanité, qui sont, et doivent plus que jamais, demeurer les vertus essentielles de l’avocat.
                            </p>
                            <p>
                                Diplômé d’études approfondies en droit immobilier public et privé il pourra vous conseiller, vous assister et vous défendre en matière de droit immobilier, de la construction, de la copropriété, de l’urbanisme et du foncier.
                            </p>
                            <p>Date de prestation de serment: 21 novembre 2007</p>
                            <a href="https://www.linkedin.com/in/fr%C3%A9d%C3%A9ric-devot-407280229/" target="_blank" class="social-icon inline-block si-small si-light si-rounded si-linkedin">
                                <i class="icon-linkedin"></i>
                                <i class="icon-linkedin"></i>
                            </a>
                            <!--
                            <a href="#" class="social-icon inline-block si-small si-light si-rounded si-facebook">
                                <i class="icon-facebook"></i>
                                <i class="icon-facebook"></i>
                            </a>
                            <a href="#" class="social-icon inline-block si-small si-light si-rounded si-twitter">
                                <i class="icon-twitter"></i>
                                <i class="icon-twitter"></i>
                            </a>
                            <a href="#" class="social-icon inline-block si-small si-light si-rounded si-gplus">
                                <i class="icon-gplus"></i>
                                <i class="icon-gplus"></i>
                            </a>
                            -->
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </Section>
    
</template>

<style>

    #team-alt .team-image > div {
        position:relative;
        height:100%;
        overflow:hidden;
    }

    #team-alt .team-image > div > img {
        transition: opacity 0.5s;
        height:100%;
        width:auto;
        min-width:100%;
        max-width:none;
        margin-left:50%;
        transform: translate(-50%, 0);
    }

    #team-alt .team-image > div > img:nth-child(2) {
        position:absolute;
        top:0px;
        left:0px;
    }

    #team-alt .team-image:hover > div > img:nth-child(2) {
        opacity: 0;
    }

    #team-alt .heading-block::after {
        margin-top:20px;
    }

    #team-alt .team-description h4 {
        margin-bottom: 5px;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-family: inherit;
    }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import Section from '@root/src/client/components/panels/layouts/canvas/Section.vue';

@Component({
  components: { 
      Section
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Team extends Vue {

    mounted() {
       
    }

    beforeDestroy() {
        
    }
    
}
</script>